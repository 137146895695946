export enum FieldType {
  text = 'text',
  textArea = 'text-area',
  email = 'email',
  password = 'password',
  select = 'select',
  checkbox = 'checkbox',
  radio = 'radio',
  array = 'array',
  comboBox = 'combo-box',
  file = 'file',
  numeric = 'numeric',
}

export const fieldTypeMap = {
  [FieldType.text]: () => import('@/components/form/fields/TextField.vue'),
  [FieldType.textArea]: () => import('@/components/form/fields/TextAreaField.vue'),
  [FieldType.email]: () => import('@/components/form/fields/EmailField.vue'),
  [FieldType.password]: () => import('@/components/form/fields/PasswordField.vue'),
  [FieldType.select]: () => import('@/components/form/fields/SelectField.vue'),
  [FieldType.checkbox]: () => import('@/components/form/fields/CheckboxField.vue'),
  [FieldType.radio]: () => import('@/components/form/fields/RadioField.vue'),
  [FieldType.array]: () => import('@/components/form/fields/ArrayField.vue'),
  [FieldType.comboBox]: () => import('@/components/form/fields/ComboBoxField.vue'),
  [FieldType.file]: () => import('@/components/form/fields/FileField.vue'),
  [FieldType.numeric]: () => import('@/components/form/fields/NumericField.vue'),
}
