import { FieldType } from '@/shared/configs/field-type.config'
import SelectOption from '@/shared/classes/form/select-option'
import FormBase from '@/shared/classes/form/form-base'

export enum FieldSize {
  full = 'full',
  half = 'half',
  quarter = 'quarter',
  threeQuarters = 'three-quarters',
  oneTwelfth = '1-12',
  twoTwelfth = '2-12',
  fourTwelfth = '4-12',
  fiveTwelfth = '5-12',
  sevenTwelfth = '7-12',
  eightTwelfth = '8-12',
  tenTwelfth = '10-12',
  elevenTwelfth = '11-12'
}

export default class Field {
  type: FieldType = FieldType.text
  key: string = null!
  entryKey: string = null!
  title: string = null!
  placeholder: string = null!
  value!: any
  disabled = false
  selectOptions: SelectOption[] = []
  arrayValues: any[] = []
  footerComponent: any = null!
  meta: any = {}
  size: FieldSize = FieldSize.full
  classes: string = ''
  rows: number | null = null
  onChange!: (value?: any, fullKey?: string, form?: FormBase) => void
  visibleIf: (values: any, index: number | null) => boolean = () => true

  setType(type: FieldType): this {
    this.type = type

    return this
  }

  setKey(key: string): this {
    this.key = key

    return this
  }

  setTitle(title: string): this {
    this.title = title

    return this
  }

  setPlaceholder(placeholder: string): this {
    this.placeholder = placeholder

    return this
  }

  setValue(value: any): this {
    this.value = value

    return this
  }

  setDisabled(disabled: boolean): this {
    this.disabled = disabled

    return this
  }

  setSelectOptions(selectOptions: SelectOption[]): this {
    this.selectOptions = selectOptions

    return this
  }

  setArrayValues(arrayValues: any[]): this {
    this.arrayValues = arrayValues

    return this
  }

  setFooterComponent(footerComponent: any): this {
    this.footerComponent = footerComponent

    return this
  }

  setMeta(meta: any): this {
    this.meta = meta

    return this
  }

  setSize(size: FieldSize): this {
    this.size = size

    return this
  }

  setClasses(classes: string): this {
    this.classes = classes

    return this
  }

  setRows(rows: number): this {
    this.rows = rows

    return this
  }

  setOnChange(onChange: (value?: any, fullKey?: string, form?: FormBase) => void): this {
    this.onChange = onChange

    return this
  }

  setVisibleIf(visibleIf: (values: any, index: number|null) => boolean): this {
    this.visibleIf = visibleIf
    return this
  }
}
