














import { Component } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/form/fields/abstract-field'
import _ from 'lodash'

@Component
export default class FileField extends AbstractField {
  url: string | null = null

  mounted() {
    const value = _.get(this.form.data, this.fullKey)

    if (value instanceof File) {
      this.readFile(value)
    } else if (value) {
      this.url = value.url
    }
  }

  async changeFile(value: any) {
    if (value) this.readFile(value)

    this.value = value
    this.removeError()
  }

  readFile(file: File) {
    const reader = new FileReader()

    reader.onload = (e: any) => {
      if (e.target.result.startsWith('data:image')) this.url = e.target.result
    }

    reader.readAsDataURL(file)
  }

  static getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
}
