











import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import Field from '@/shared/classes/form/field'
import FormBase from '@/shared/classes/form/form-base'
import { fieldTypeMap } from '@/shared/configs/field-type.config'

@Component
export default class FormField extends Vue {
  @Prop() field!: Field
  @Prop() form!: FormBase
  @Prop() fullKey!: string

  get component(): any {
    if (_.has(fieldTypeMap, this.field.type)) {
      return _.get(fieldTypeMap, this.field.type)
    }

    console.log(`${ this.field.type } is not defined`)
    return null
  }

  get errors(): string[] {
    return _.get(this.form.errors, `${ this.field.key }.messages`, [])
  }
}
