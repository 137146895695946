



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import http from '@/shared/http'
import FormBase from '@/shared/classes/form/form-base'
import FormField from '@/components/form/FormField.vue'
import presetInitialFormValues from '@/shared/helpers/components/form/preset-initial-values.helper'
import Field from '@/shared/classes/form/field'
import { FieldType } from '@/shared/configs/field-type.config'
import FileField from '@/components/form/fields/FileField.vue'

@Component({
  components: { FormField }
})
export default class Form extends Vue {
  @Prop() form!: FormBase
  @Watch('form.data', { deep: true }) private handleOnChange() {
    this.form.onChange && this.form.onChange(this.form)
  }
  rerenderForm: boolean = false

  async created() {
    await presetInitialFormValues(this.form)
  }

  async submit(validate: boolean = false) {
    const { uuid, onSuccess, method } = this.form
    let { endpoint, validateBefore } = this.form
    const data = this.prepareData()
    const call: any = http[method]

    this.form.setLoading(true)

    if (! endpoint) {
      this.form.setLoading(false)
      return onSuccess && onSuccess(data)
    }

    endpoint = uuid ? `${ endpoint }/${ uuid }` : (validate && validateBefore ? validateBefore : endpoint)

    await call(endpoint, data, this.defaultParams())
      .then(async (response: any) => {
        this.form.setErrors({})
        onSuccess && await onSuccess(response.data)
        return response
      })
      .catch((error: any) => this.form.catchErrors(error))
      .finally(() => this.form.setLoading(false))

    if (Object.keys(this.form.errors).length === 0) return false

    return {
      errors: Object.keys(this.form.errors).length > 0,
      data: this.form.errors
    }
  }

  reRender() {
    this.rerenderForm = true
    setTimeout(() => this.rerenderForm = false)
  }

  prepareData(): any {
    let data = _.cloneDeep(this.form.data)

    const recursive = async (key: string, found?: Field | any, parentIndex: number | null = null) => {
      if (found && found.type === FieldType.file) {
        const file: any = {}

        const value = _.get(data, key)
        if (! value) return

        const base64: any = await FileField.getBase64(value)

        _.set(file, 'file_name', value.name)
        _.set(file, 'file', base64)

        _.set(data, key, file)
      }

      if (! found) found = this.form.fields.find((field: Field) => field.key === key)
      if (! found || ! found.visibleIf(data, parentIndex)) _.unset(data, key)
    }

    Object.keys(data)
      .map((key: string) => recursive(key, this.form.fields.find((field: Field) => field.key === key)))

    data = this.injectValuesToData(data)

    return this.form.files ? this.prepareDataForFileFormat(data) : data
  }

  private prepareDataForFileFormat(data: any) {
    const { uuid, fields }: any = this.form
    const formData = new FormData()

    formData.append('_method', uuid ? 'PUT' : 'POST')

    Object.keys(data).forEach((key: string) => {
      const found = fields.find((field: Field) => field.key === key)
      const value = data[key]

      if (found && found.type === FieldType.file && value instanceof Object && ! (value instanceof File)) {
        formData.append(key, '')

        return
      }

      if (found && found.type === FieldType.file && ! value) return

      if (! value || typeof value === 'undefined') {
        formData.append(key, '')

        return
      }

      formData.append(key, value)
    })

    return formData
  }

  private injectValuesToData(data: any): void {
    const recursive = (fullKey: string) => {
      const value: any = _.get(this.form.injectValues, fullKey)
      if (value instanceof Object) {
        Object.keys(value).forEach((subKey: string) => recursive(`${ fullKey }.${ subKey }`))
        return
      }

      if (_.get(data, fullKey, null) === null || _.get(data, fullKey, null) === '') _.set(data, fullKey, value)
    }

    if (this.form.injectValues) {
      Object.keys(this.form.injectValues).forEach(recursive)
    }

    return data
  }

  defaultParams() {
    return {
      headers: {
        ['Content-Type']: this.form.files ? 'multipart/form-data' : 'application/json'
      }
    }
  }
}
