import Field from '@/shared/classes/form/field'
import HttpMethod from '@/shared/configs/http-method.config'
import { AxiosError } from 'axios'

interface SubmitInterface {
  text?: string,
  loadingText?: string,
  classes?: string
}

export default class FormBase {
  uuid: string | number = null!
  method: HttpMethod = HttpMethod.POST
  endpoint: string = null!
  validateBefore: string = null!
  fields: Field[] = []
  data: any = {}
  entry: any = null
  initialValues: any = null
  injectValues!: any
  errors: any = {}
  submit: SubmitInterface | boolean = {
    text: 'Submit',
    loadingText: 'Submitting...',
    classes: 'd-flex justify-content-start align-items-center'
  }
  onSuccess: (data: any) => void = null!
  onChange: (form: FormBase) => void = null!
  loading = false
  files: boolean = false
  errorMessage: string|null = null

  setUuid(uuid: string | number): this {
    this.uuid = uuid

    return this
  }

  setMethod(method: HttpMethod): this {
    this.method = method

    return this
  }

  setEndpoint(endpoint: string): this {
    this.endpoint = endpoint

    return this
  }

  setValidateBefore(endpoint: string): this {
    this.validateBefore = endpoint

    return this
  }

  setFields(field: Field[]): this {
    this.fields = field

    return this
  }

  setData(data: any): this {
    this.data = data

    return this
  }

  setEntry(entry: any): this {
    this.entry = entry

    return this
  }

  setInitialValues(initialValues: any): this {
    this.initialValues = initialValues

    return this
  }

  setInjectValues(injectValues: any): this {
    this.injectValues = injectValues

    return this
  }

  setErrors(errors: any): this {
    this.errors = errors

    return this
  }

  setSubmit(submit: SubmitInterface | boolean): this {
    this.submit = submit

    return this
  }

  setOnSuccess(onSuccess: (data: any) => void): this {
    this.onSuccess = onSuccess

    return this
  }

  setOnChange(onChange: (form: FormBase) => void): this {
    this.onChange = onChange

    return this
  }

  setLoading(loading: boolean): this {
    this.loading = loading

    return this
  }

  setFiles(files: boolean): this {
    this.files = files

    return this
  }

  public catchErrors(error: AxiosError<any>): void {
    if (! error.response) {
      return
    }

    if (error.response.data.errors) {
      if (error.response.status === 422) this.parseErrors(error.response.data.errors)
    } else {
      this.errorMessage = error.response.data.message
    }
  }

  private parseErrors(errors: any): void {
    const formErrors: any = {}

    Object.keys(errors).forEach((key: string) => {
      formErrors[key] = {
        has: true,
        count: errors[key].length,
        messages: errors[key]
      }
    })

    this.setErrors(formErrors)
  }
}
