import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import Field from '@/shared/classes/form/field'
import FormBase from '@/shared/classes/form/form-base'

@Component
export default class AbstractField extends Vue {
  @Prop() field!: Field
  @Prop() form!: FormBase
  @Prop() fullKey!: string

  get value() {
    if (this.form) {
      return _.get(this.form.data, this.fullKey, this.field.value)
    }

    return this.field.value
  }

  set value(value) {
    this.removeError()

    if (this.form) {
      const clonedData = _.cloneDeep(this.form.data)
      _.set(clonedData, this.fullKey, value)

      this.form.setData(clonedData)

      this.field.onChange && this.field.onChange(value, this.fullKey, this.form)
      return
    }

    this.field.value = value
  }

  getErrors(): string[] {
    return _.get(this.form.errors, this.fullKey ? this.fullKey : this.field.key, [])
  }

  removeError() {
    if (! this.form) return null
    this.form.errorMessage = null

    delete this.form.errors[this.fullKey]
  }
}
